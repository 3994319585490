<script>
import HeroBar from '@/components/HeroBar.vue'
import Search from '@/components/dashboard/Search.vue'
import moment from 'moment/moment'
import TitleBarNew from '@/components/TitleBarNew.vue'

export default {
  name: 'Year',
  components: { TitleBarNew, Search, HeroBar },
  data () {
    return {
      titleStack: [
        {
          name: 'Dashboard',
          routeName: 'home',
          params: []
        },
        {
          name: 'Modes',
          routeName: 'shipment.types'
        },
        {
          name: this.$route.params.type,
          routeName: 'shipment.type',
          params: { type: this.$route.params.type }
        },
        {
          name: this.$route.params.year
          // routeName: 'shipment.type',
          // params: { year: this.$route.params.year, type: this.$route.params.type }
        }
      ],
      invoices: [],
      search: '',
      month: '',
      count: '',
      states: [],
      form: {
        date: '',
        end: '',
        state: ''
      }
    }
  },
  methods: {
    fetch () {
      this.$router.push({ query: { search: this.search, state: this.form.state, date: `${this.parseDate(this.form.date)}`, end: `${this.parseDate(this.form.end)}` } })
      this.axios.get(`/v2/app/type/${this.$route.params.type}/years/${this.$route.params.year}?search=${this.search}
      &month=${this.month}&version=v2&transit=${this.form.state}&date=${this.parseDate(this.form.date)}&end=${this.parseDate(this.form.end)}`)
        .then(response => {
          this.invoices = response.data.invoices
          this.count = response.data.count
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchData (search) {
      this.search = search
      this.fetch()
    },
    fetchStates () {
      this.axios.get('/v1/app/states')
        .then(response => {
          this.states = response.data.states
        })
        .catch(error => {
          console.log(error)
        })
    },
    clearDate () {
      this.form.date = ''
      this.fetch()
    },
    clearDateEnd () {
      this.form.end = ''
      this.fetch()
    },
    parseDate (date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      }

      return ''
    }
  },
  created () {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
    if (this.$route.query.state) {
      this.form.state = this.$route.query.state
    }
    if (this.$route.query.date) {
      this.form.date = new Date(this.$route.query.date)
    }
    if (this.$route.query.end) {
      this.form.end = new Date(this.$route.query.end)
    }
    this.fetch()
    this.fetchStates()
  }
}
</script>

<template>
  <div>
    <title-bar-new :title-stack="titleStack" />
    <hero-bar>
     {{ $route.params.type }}: Year {{ this.$route.params.year }}
    </hero-bar>

    <section class="section is-main-section has-background-white">
      <div class="mb-2 p-4">
        <div class="columns mb-4">
          <div class="column">
            <Search
              :search-placeholder="'Search by tracking number'"
              :initial-value="search"
              @search="searchData"
            />
          </div>
          <div class="column">
            <b-select
              v-model="form.state"
              placeholder="Select a state"
              expanded
              @input="fetch"
            >
              <option value="">
                Select a state
              </option>
              <option
                v-for="option in states"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </div>
          <div class="column">
            <b-datepicker
              v-model="form.date"
              placeholder="Date/Start Date"
              icon="calendar-today"
              :min-date="new Date(parseInt(this.$route.params.year), 0)"
              :max-date="new Date(parseInt(this.$route.params.year), 11)"
              locale="en-CA"
              :date-formatter="parseDate"
              editable
              :icon-right="form.date ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              @input="fetch"
            />
          </div>
          <div class="column">
            <b-datepicker
              v-model="form.end"
              placeholder="End Date"
              icon="calendar-today"
              :min-date="new Date(parseInt(this.$route.params.year), 0)"
              :max-date="new Date(parseInt(this.$route.params.year), 11)"
              locale="en-CA"
              :date-formatter="parseDate"
              editable
              :icon-right="form.end ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDateEnd"
              @input="fetch"
            />
          </div>
        </div>
        <div class="mb-1" v-if="count">
          <small class="text-bold has-text-success">{{ count }} Invoice(s)</small>
        </div>
        <hr>
        <div class="columns is-multiline">
          <div v-for="(invoice, idx) in invoices" :key="idx" class="column is-one-quarter">
            <router-link :to="{name: 'shipment.invoice', params: {year: $route.params.year, type: $route.params.type, invoice: invoice}}">{{ invoice }}</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
